import 'slick-carousel/slick/slick.css';
import './src/styles/tailwind.scss';
import './src/styles/global.scss';

import { IconProvider } from './src/components/IconRegistry/IconRegistry';

export const wrapPageElement = ({ element }) => {
  return <IconProvider>{element}</IconProvider>;
};

// Store the previous path to the window for use in our components.
// @see https://github.com/gatsbyjs/gatsby/issues/10410
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];

  if (window.locations[locations.length - 1] !== window.location.href) window.locations.push(window.location.href);

  window.previousPath = window.locations[locations.length - 2];
};
